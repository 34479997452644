<template>
    <component
        :class="containerClassName"
        :is="getSwitchContainerElement"
        :for="getSwitchFor"
    >
        <template v-if="isInverted">
            <slot name="label">
                <span
                    class="nebula-switch__text"
                    v-if="labelText"
                    v-text="labelText"
                />
            </slot>
            <slot name="checkbox" />
            <span class="nebula-switch__visual">
                <NebulaIcon
                    class="nebula-switch__checked-icon"
                    symbolId="circle--filled"
                    size="s"
                />
            </span>
        </template>
        <template v-else>
            <slot name="checkbox" />
            <span class="nebula-switch__visual">
                <NebulaIcon
                    class="nebula-switch__checked-icon"
                    symbolId="circle--filled"
                    size="s"
                />
            </span>
            <slot name="label">
                <span
                    class="nebula-switch__text"
                    v-if="labelText"
                    v-text="labelText"
                />
            </slot>
        </template>
    </component>
</template>

<script>
import NebulaIcon from '@/components/Icon/NebulaIcon.vue';

export default {
    name: 'NebulaSwitch',
    components: {
        NebulaIcon,
    },
    props: {
        id: {
            type: [String, Number],
        },
        isCompressed: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isInverted: {
            type: Boolean,
            default: false,
        },
        labelText: {
            type: String,
        },
    },
    computed: {
        containerClassName() {
            const className = [
                'nebula-switch__container',
                {
                    'nebula-switch__container--compressed': this.isCompressed === true,
                    'nebula-switch__container--inverted': this.isInverted === true,
                    'nebula-switch__container--disabled': this.isDisabled === true,
                },
            ];
            return className;
        },

        getSwitchContainerElement() {
            return this.labelText ? 'label' : 'span';
        },

        getSwitchFor() {
            return this.labelText ? this.id : null;
        },
    },
};
</script>

<style lang="stylus">
:root {
    --nebula-switch-background-color-checked: $nebula-color-platform-interactive-850;
    --nebula-switch-background-color-disabled: $nebula-color-platform-interface-300;
    --nebula-switch-background-color: $nebula-color-platform-interface-500;
    --nebula-switch-icon-fill-disabled: $nebula-color-platform-interface-500;
    --nebula-switch-icon-fill: $nebula-color-white;
    --nebula-switch-label-font-size: $nebula-font-size-body-2;
    --nebula-switch-label-text-color-disabled: $nebula-color-platform-interface-700;
    --nebula-switch-label-text-color: $nebula-color-neutral-500;
}

.nebula-switch__container {
    align-items: center;
    display: inline-flex;
    gap: 0 $nebula-space-1x;
    position: relative;
    width: max-content;

    .nebula-switch__visual {
        align-items: center;
        background: var(--nebula-switch-background-color);
        border-radius: 100px;
        display: flex;
        height: $nebula-space-4x;
        padding: $nebula-space-1x;
        transition: background $nebula-transition-default;
        width: $nebula-space-8x;
    }

    .nebula-switch__checked-icon {
        fill: var(--nebula-switch-icon-fill);
        height: $nebula-space-3x;
        transition: transform $nebula-transition-default;
        width: $nebula-space-3x;
    }

    .nebula-switch__text {
        line-height: $nebula-font-line-height-default;
        color: var(--nebula-switch-label-text-color);
        cursor: pointer;
        font-family: $nebula-font-family-display;
        font-size: var(--nebula-switch-label-font-size);
    }

    .nebula-switch__input {
        cursor: pointer;
        height: 100%;
        opacity: 0;
        position: absolute;
        width: 100%;
        z-index: 1;

        &:focus-visible {
            & + .nebula-switch__visual {
                outline: 5px auto Highlight;
                outline: 5px auto -webkit-focus-ring-color;
            }
        }

        &:checked {
            & + .nebula-switch__visual {
                background: var(--nebula-switch-background-color-checked);
            }

            & + .nebula-switch__visual .nebula-switch__checked-icon {
                transform: translateX(100%);

                [dir="rtl"] & {
                    transform: translateX(-100%);
                }
            }
        }

        &:disabled,
        &[aria-disabled="true"] {
            pointer-events: none;

            & + .nebula-switch__visual {
                background: var(--nebula-switch-background-color-disabled);

                .nebula-switch__checked-icon {
                    fill: var(--nebula-switch-icon-fill-disabled);
                }
            }
        }
    }

    &--compressed {
        .nebula-switch__visual {
            height: $nebula-space-3x;
            padding: $nebula-space-half;
            width: $nebula-space-5x;
        }

        .nebula-switch__checked-icon {
            height: $nebula-space-2x;
            width: $nebula-space-2x;
        }
    }

    &--disabled {
        pointer-events: none;

        .nebula-switch__text {
            color: var(--nebula-switch-label-text-color-disabled);
            cursor: default;
        }
    }
}

</style>
